<style scoped>
.day-before-title {
  color: #f0f0f0;
}
.day-after-title {
  color: blue;
}
.day {
  border: 1px solid gray;
  border-radius: 5px;
}
.day-after {
  border-bottom: 1px solid gray;
  background-color: #a6cae7;
  font-size: 0.8em;
}
.day-before {
  border-bottom: 1px solid gray;
  background-color: #f0f0f0;
  color: #a0a0a0;
  font-size: 0.8em;
}
</style>

<template>
  <b-col cols="auto" class="mr-3 day mb-2">
    <div class="titles title-day" v-bind:class="isDayBefore() ? 'day-before' : 'day-after'">
      <div cols>{{ dateTitle }}</div>
      <div
        v-if="listMeals.Category!='' || listMeals.Act!=''"
        cols="auto"
      >{{ listMeals.Category }} {{ listMeals.Act }}</div>
      <div v-else>-</div>
    </div>
    <b-button-group size="sm" vertical class="align-top mr-1">
      <div
        class="w-100"
        v-bind:class="isDayBefore() || isAfterBreakfast() ? 'day-before-title' : 'day-after-title'"
      >B</div>
      <meals-button-week
        v-for="item in settings.MealOptions['1']"
        v-bind:item="item"
        v-bind:key="item.type"
        meal="Breakfast"
        v-bind:resident="listMeals"
        v-on:listChange="$emit('listChange',$event)"
      />
    </b-button-group>
    <b-button-group size="sm" vertical class="align-top mr-1">
      <div
        class="w-100"
        v-bind:class="isDayBefore() || isAfterLunch() ? 'day-before-title' : 'day-after-title'"
      >L</div>
      <meals-button-week
        v-for="item in settings.MealOptions['2']"
        v-bind:item="item"
        v-bind:key="item.type"
        meal="Lunch"
        v-bind:resident="listMeals"
        v-on:listChange="$emit('listChange',$event)"
      />
    </b-button-group>
    <b-button-group size="sm" vertical class="align-top">
      <div
        class="w-100"
        v-bind:class="isDayBefore() || isAfterDinner() ? 'day-before-title' : 'day-after-title'"
      >D</div>
      <meals-button-week
        v-for="item in settings.MealOptions['3']"
        v-bind:item="item"
        v-bind:key="item.type"
        meal="Dinner"
        v-bind:resident="listMeals"
        v-on:listChange="$emit('listChange',$event)"
      />
    </b-button-group>
  </b-col>
</template>

<script>
import moment from "moment";
import MealsButtonWeek from "./MealsButtonWeek.vue";

export default {
  props: {
    listMeals: Object
  },
  computed: {
    test() {
      var td = moment(this.listMeals.Date).endOf("day");
      return moment().isAfter(td);
    },
    dateTitle() {
      return moment(this.listMeals.Date).format("dd D");
    },
    settings() {
      return this.$store.state.settings;
    }
  },
  components: {
    MealsButtonWeek
  },
  methods: {
    isAfterBreakfast() {
      var tb = moment(this.listMeals.Date)
        .hour(8)
        .minute(0);
      return moment().isAfter(tb);
    },
    isAfterLunch() {
      var tl = moment(this.listMeals.Date)
        .hour(12)
        .minute(0);
      return moment().isAfter(tl);
    },
    isAfterDinner() {
      var td = moment(this.listMeals.Date)
        .hour(18)
        .minute(0);
      return moment().isAfter(td);
    },
    isDayBefore() {
      var td = moment(this.listMeals.Date).endOf("day");
      return moment().isAfter(td);
    }
  }
};
</script>

