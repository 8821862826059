<style scoped>
</style>

<template>
  <div>
    <h4 class="text-left mt-3">{{ infoUser.name }}</h4>
    <nav-week-row />
    <div v-if="listMeals.length>0">
      <div>
        <b-row no-gutters class="text-center mt-1">
          <row-residents-week-day
            v-for="item in listMeals"
            v-bind:listMeals="item"
            v-bind:key="item.Date"
            v-on:listChange="listChange($event)"
          />
          <b-col></b-col>
        </b-row>
      </div>

      <b-row class="mt-2">
        <b-col cols="auto" class="text-left">
          <b-button @click="saveWeekMealsTemplate()" class="mr-1 mb-1" variant="primary">
            <font-awesome-icon class="mr-1" icon="file-export" :style="{ color: 'white' }" />Save as Template
          </b-button>
          <b-button @click="loadWeekTemplate()" variant="primary" class="mr-1 mb-1">
            <font-awesome-icon icon="file-import" :style="{ color: 'white' }" />Load from Template
          </b-button>
        </b-col>
        <b-col cols="auto">
          <b-input-group>
            <template v-slot:prepend>
              <b-input-group-text>All Week</b-input-group-text>
            </template>
            <b-input-group-append>
              <b-button @click="saveWeekMeals('X')" variant="success">X</b-button>
            <b-button @click="saveWeekMeals('O')" variant="danger">O</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>

      <!-- Show list info entered -->
      <b-row class="text-center mt-3" v-if="!gettingLastEntryCurrentUser">
        <div class="ml-3" v-html="lastEntryCurrentUser" style="font-size: 0.9em;"></div>
      </b-row>
      <b-row class="text-center mt-3" v-else>
        <font-awesome-icon icon="spinner" :style="{ color: 'gray' }" spin />
      </b-row>
    </div>
    <div v-else>
      <font-awesome-icon icon="sync-alt" spin size="3x" :style="{ color: 'blue' }" />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import RowResidentsWeekDay from "@/components/RowResidentsWeekDay.vue";
import NavWeekRow from "@/components/NavWeekRow.vue";

export default {
  data: function() {
    return {
      listMeals: [],
      gettingLastEntryCurrentUser: false,
      lastEntryMissing: [],
      count: 0
    };
  },
  computed: {
    currentWeek() {
      return this.$store.getters.currentWeek;
    },
    infoUser() {
      return this.$store.state.userInfo;
    },
    reloadData() {
      return this.$store.state.reloadData;
    },
    lastEntryCurrentUser() {
      var stDate = this.lastEntryMissing.DateMissing;
      return moment(stDate).calendar(null, {
        sameDay:
          "[<span style='color: red'>Your meal account is missing TODAY's info!</span>]",
        nextDay:
          "[<span style='color: red'>Your meal account is missing Tomorrow's info!</span>]",
        nextWeek:
          "[<span style='color: green'>You are all set until ]dddd[</span>]",
        lastDay: "[Yesterday]",
        lastWeek: "[Last] dddd",
        sameElse:
          "[<span style='color: green'>Good! You are all set until ]MMMM D[</span>]"
      });
    },
    test() {
      return this.listMeals[0];
    }
  },
  components: {
    RowResidentsWeekDay,
    NavWeekRow
  },
  mounted() {
    this.getWeekMeals();
    this.updateLastDayMissing();
  },
  watch: {
    currentWeek: function() {
      this.getWeekMeals();
      this.updateLastDayMissing();
    },
    reloadData: function() {
      if (this.reloadData) {
        this.getWeekMeals();
        this.updateLastDayMissing();
        this.$store.commit("reloadData", false);
      }
    }
  },
  methods: {
    getWeekMeals() {
      this.count++;
      var url = "getWeekMeals.php";
      var ends = moment(this.currentWeek)
        .add(1, "w")
        .subtract(1, "d")
        .format("YYYY-MM-DD");
      var data = { start: this.currentWeek, end: ends, id: this.infoUser.id };
      this.listMeals = [];
      this.$http.post(url, data, { emulateJSON: true }).then(
        res => {
          this.listMeals = res.body.Meals;
          this.count = 0;
        },
        // eslint-disable-next-line no-unused-vars
        err => {
          this.listResidents = [];
          this.listMeals = [];
          if (this.count < 10) {
            this.$bvToast.toast(`Trying to reconect...`, {
              title: "Connecction lost",
              variant: "info",
              toaster: "b-toaster-top-center",
              autoHideDelay: 2500,
              appendToast: false
            });
            setTimeout(this.getWeekMeals, 3000);
            setTimeout(this.updateLastDayMissing, 3000);
          } else {
            this.$bvToast.toast(
              `Sorry, check your Internet connection and try again...`,
              {
                title: "Connecction lost",
                variant: "info",
                toaster: "b-toaster-top-center",
                noAutoHide: true,
                appendToast: false
              }
            );
          }
        }
      );
    },
    saveWeekMeals(type) {
      var url = "saveWeekMeals.php";
      var dates = [];
      var starts = moment(this.currentWeek);
      for (let index = 0; index < 8; index++) {
        dates.push(starts.format("YYYY-MM-DD"));
        starts.add(1, "d");
      }
      var data = {
        dates: dates,
        id: this.infoUser.id,
        type: type
      };
      this.$http.post(url, data, { emulateJSON: true }).then(
        // eslint-disable-next-line no-unused-vars
        res => {
          this.getWeekMeals();
          this.updateLastDayMissing();
        },
        // eslint-disable-next-line no-unused-vars
        err => {}
      );
    },
    saveWeekMealsTemplate() {
      var url = "saveWeekTemplate.php";
      var data = { list: this.listMeals, id: this.infoUser.id };
      this.$http.post(url, data, { emulateJSON: true }).then(
        // eslint-disable-next-line no-unused-vars
        res => {
          this.$bvToast.toast(`Saved!`, {
            title: "Template",
            variant: "info",
            toaster: "b-toaster-top-center",
            autoHideDelay: 2000,
            appendToast: false
          });
        },
        // eslint-disable-next-line no-unused-vars
        err => {}
      );
    },
    loadWeekTemplate() {
      var url = "loadWeekTemplate.php";
      var dates = [];
      var starts = moment(this.currentWeek);
      for (let index = 0; index < 8; index++) {
        dates.push(starts.format("YYYY-MM-DD"));
        starts.add(1, "d");
      }
      var data = { dates: dates, id: this.infoUser.id };
      this.$http.post(url, data, { emulateJSON: true }).then(
        // eslint-disable-next-line no-unused-vars
        res => {
          this.getWeekMeals();
          this.updateLastDayMissing();
        },
        // eslint-disable-next-line no-unused-vars
        err => {}
      );
    },
    updateLastDayMissing() {
      var url = "getLastDayMissingId.php";
      var id = this.infoUser.id;
      var data = { date: moment(this.currentDay).format("YYYY-MM-DD"), id: id };
      this.$http.post(url, data, { emulateJSON: true }).then(
        res => {
          this.lastEntryMissing = res.body.Missing;
          this.gettingLastEntryCurrentUser = false;
        },
        // eslint-disable-next-line no-unused-vars
        err => {}
      );
    },
    listChange(obj) {
      var resident = obj.resident;
      var newDate = resident.Date;
      var meal = obj.meal;
      var status = obj.type;

      var index = this.listMeals.findIndex(x => x.Date == newDate);
      if (this.listMeals[index][meal] == status) {
        this.listMeals[index][meal] = "";
      } else {
        this.listMeals[index][meal] = status;
      }
      this.updateMeals(this.infoUser.id, meal, status, newDate);
    },
    updateMeals(id, meal, status, date) {
      var url = "updateMeals.php";
      var data = {
        date: date,
        id: id,
        meal: meal,
        status: status,
        guest: 0
      };
      if (meal == "Dinner") {
        this.gettingLastEntryCurrentUser = true;
      }
      this.$http.post(url, data, { emulateJSON: true }).then(
        // eslint-disable-next-line no-unused-vars
        res => {
          if (meal == "Dinner") {
            this.updateLastDayMissing();
          }
        },
        // eslint-disable-next-line no-unused-vars
        err => {}
      );
    }
  }
};
</script>