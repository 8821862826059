<style scoped>
</style>

<template>
  <b-button
    @click="$emit('listChange',{ resident: resident, meal: meal, type:item.type})"
    v-bind:disabled="isAfterMeal()"
    v-bind:variant="resident[meal]==item.type ? item.color : 'light'"
  >{{ item.type }}</b-button>
</template>

<script>
import moment from "moment";

export default {
  props: ["meal", "item", "resident"],
  computed: {},
  methods: {
    isAfterMeal() {
      var b = false;
      switch (this.meal) {
        case "Breakfast":
          b = this.isAfterBreakfast();
          break;
        case "Lunch":
          b = this.isAfterLunch();
          break;
        case "Dinner":
          b = this.isAfterDinner();
          break;
        default:
        // code block
      }
      return b;
    },
    isAfterBreakfast() {
      var tb = moment(this.resident.Date)
        .hour(8)
        .minute(0);
      return moment().isAfter(tb);
    },
    isAfterLunch() {
      var tl = moment(this.resident.Date)
        .hour(12)
        .minute(0);
      return moment().isAfter(tl);
    },
    isAfterDinner() {
      var td = moment(this.resident.Date)
        .hour(18)
        .minute(0);
      return moment().isAfter(td);
    }
  }
};
</script>